export enum HTTP {
  NO_CONTENT = 204,
  OK = 200,
  UNAUTHORIZED = 401,
  CREATED = 201,
}
export enum EFilters {
  FILTERS_CITY = 'city',
  FILTERS_CLASS = 'class',
  FILTERS_COUNTY = 'county',
  FILTERS_DISTRICT = 'district',
  FILTERS_SUBDIVISION = 'subdivision',
  FILTERS_ZIP = 'zip',
  FILTERS_TYPE = 'type',
  FILTERS_STYLE = 'style',
  FILTERS_TIME_FRAME = 'time-frame',
  FILTERS_SCHOOL0 = 'school0',
  FILTERS_SCHOOL1 = 'school1',
  FILTERS_SCHOOL2 = 'school2',
}

export enum EReportTypes {
  COMBINED = 'combined',
  LISTING = 'listing',
  SELLING = 'selling',
}

export enum EListTypes {
  COMBINED = 'combined',
  LISTING = 'listing',
  SELLING = 'selling',
}

export enum EReportUnit {
  AGENTS = 'agents',
  OFFICES = 'offices',
  FIRMS = 'firms',
  BRANDS = 'brands',
}

export enum EReportName {
  AGENTS = 'agent-performance',
  AGENTS_REGISTERED = 'agent-performance-registered-agents',
  AGENTS_NEW_REGISTERED = 'agent-performance-new-registered-agents',
  AGENTS_COMPARATIVE = 'agent-comparative-performance',
  AGENTS_NEW_ACTIVE = 'new-active-agent-performance',
  AGENTS_YEARBOOK = 'agent-performance-yearbook',
  AGENTS_STATUS = 'agent-performance-status',
  AGENTS_DISTRIBUTION = 'agent-performance-distribution',
  AGENT_DISTRIBUTION = 'agent-distribution',
  AGENT_MIGRATION_SUMMARY = 'agent-migration-summary',
  AGENT_TRANSACTIONS = 'agent-transactions',
  AGENT_ROSTER_MIGRATION = 'agent-roster-migration',
  AGENT_HISTORY = 'agent-history',
  AGENT_DETAILED_SUMMARY = 'agent-detailed-summary',
  OFFICES = 'office-performance',
  OFFICES_REGISTERED = 'office-performance-registered-agents',
  OFFICES_REGISTERED_AGENT_COUNT = 'office-registered-agent-count',
  OFFICES_COMPARATIVE_PERFORMANCE = 'office-comparative-performance',
  OFFICES_TOP_TEN = 'office-top-ten',
  OFFICES_DISTRIBUTION = 'office-performance-distribution',
  OFFICE_DISTRIBUTION = 'office-distribution',
  OFFICE_TRANSACTIONS = 'office-transactions',
  OFFICE_MIGRATION = 'office-migration',
  FIRMS = 'firm-performance',
  FIRMS_REGISTERED = 'firm-performance-registered-agents',
  FIRMS_TOP_TEN = 'firm-top-ten',
  FIRM_TRANSACTIONS = 'firm-transactions',
  FIRMS_COMPARATIVE_PERFORMANCE = 'firm-comparative-performance',
  FIRMS_DISTRIBUTION = 'firm-performance-distribution',
  FIRMS_REGISTERED_AGENT_COUNT = 'firm-registered-agent-count',
  FIRM_DISTRIBUTION = 'firm-distribution',
  BRANDS = 'brand-performance',
  BRANDS_REGISTERED = 'brand-performance-registered-agents',
  BRANDS_TOP_TEN = 'brand-top-ten',
  BRANDS_COMPARATIVE_PERFORMANCE = 'brand-comparative-performance',
  BRANDS_DISTRIBUTION = 'brand-performance-distribution',
  BRANDS_REGISTERED_AGENT_COUNT = 'brand-registered-agent-count',
  BRAND_DISTRIBUTION = 'brand-distribution',
  BRAND_TRANSACTIONS = 'brand-transactions',
  PERFORMANCE = 'performance',
  MARKET = 'market',
  MARKET_COMPARATIVE = 'market-comparative',
  MARKET_PERFORMANCE_DISTRIBUTION = 'market-performance-distribution',
  MARKET_COMPARATIVE_DISTRIBUTION = 'market-comparative-distribution',
  MONTHLY_HOUSING_STATISTICS = 'monthly-housing-statistics',
  MARKET_OVERVIEW_PRICE_RANGE = 'market-overview-price-range',
}

export enum EMeasures {
  UNITS = 'units',
  VOLUME = 'volume',
}

export enum EDistributionData {
  PRICE_10000 = 'price10000',
  PRICE_25000 = 'price25000',
  PRICE_50000 = 'price50000',
  PRICE_100000 = 'price100000',
  PRICE_500000 = 'price500000',
  AVG_RATIO_RANGE = 'avgRatioRange',
  MONTH = 'month',
  QUARTER = 'quarter',
  PROPERTY_TYPE = 'propertyType',
  TYPE = 'type',
  STYLE = 'style',
  COUNTY = 'county',
  CITY = 'city',
  ZIP_CODE = 'zipCode',
  DISTRICT = 'district',
  SUBDIVISION = 'subdivision',
}

export enum EMarketDistributionData {
  PRICE_10000 = 'price10000',
  PRICE_25000 = 'price25000',
  PRICE_50000 = 'price50000',
  PRICE_100000 = 'price100000',
  PRICE_500000 = 'price500000',
  AVG_RATIO_RANGE = 'avgRatioRange',
  MONTH = 'month',
  QUARTER = 'quarter',
  PROPERTY_TYPE = 'propertyType',
  TYPE = 'type',
  STYLE = 'style',
  COUNTY = 'county',
  CITY = 'city',
  ZIP_CODE = 'zipCode',
  DISTRICT = 'district',
  SUBDIVISION = 'subdivision',
}

export enum EAdvancedPdfData {
  AVERAGE = 'average',
  MEDIAN = 'median',
}

export enum EOrder {
  ASC = 'asc',
  DESC = 'desc',
  TOP = 'top',
}

export enum ECombinedRequiredFieldsForAgents {
  AGENT_NAME = 'agentName',
  BRAND = 'brand',
  OFFICE_NAME = 'officeName',
  MLS = 'mls',
  AREA = 'area',
  // OFFICE_ADDRESS = 'officeAddress',
}

export enum ECombinedRequiredFieldsForFirms {
  FIRM_NAME = 'firmName',
  MLS = 'mls',
  AREA = 'area',
}

export enum ECombinedRequiredFieldsForOffices {
  OFFICE_NAME = 'officeName',
  MLS = 'mls',
  AREA = 'area',
}

export enum ECombinedRequiredFieldsForBrands {
  BRAND_NAME = 'brandName',
  MLS = 'mls',
  AREA = 'area',
}

export enum ETimeFrames {
  CUSTOM = 'custom',
  YEAR = '12 months',
  HALF_YEAR = '6 months',
  THREE_MONTHS = '3 months',
  MONTH = '1 month',
}

export enum ESchoolTypes {
  SCHOOL_JUNIOR = 'schoolJunior',
  SCHOOL_ELEM = 'schoolElem',
  SCHOOL_HIGH = 'schoolHigh',
}

export enum EFileTypes {
  TYPE_CSV = 'csv',
  TYPE_XLSX = 'xlsx',
  TYPE_PDF = 'pdf',
}

export enum EPerPage {
  Rows10 = '10',
  Rows25 = '25',
  Rows50 = '50',
  Rows100 = '100',
}
